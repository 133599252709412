import loadable from '@loadable/component';

const QualityCertificates = loadable(() => import(/*webpackChunkName:"sales-agr"*/'./Certificates'));
const QualityCertificatesDetails = loadable(() => import(/*webpackChunkName:"sales-agr"*/'./QualityCertificate'));

function selectPropsFromPage({
  docs,
  size,
  filter,
  brands,
  doc,
  totalCount
}) {
  return {
    documents: docs,
    size,
    filter,
    brands,
    document: doc,
    totalCount
  };
}

/* eslint-disable react/no-multi-comp */
export function qualityCertificatesRenderer(page) {
  return <QualityCertificates {...selectPropsFromPage(page)} />;
}
export function qualityCertificatesDetailsRenderer(page) {
  return <QualityCertificatesDetails {...selectPropsFromPage(page)} />;
}