import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestCertificates } from './actions';
import { PageComponentNames } from '../componentNames';
import DateOnly from 'date-only';

export const size = 10;

export default function (query) {
  
    return function ({ params, options: pageOptions }, state$, { api }) {
    const filter = normalizeFilter(params && params.filter);

    const onlyItems = pageOptions && pageOptions.onlyItems;
    const backTo = pageOptions && pageOptions.backTo;
      const options = createOptions(params, filter, onlyItems);

      if (onlyItems)
        return of({
          action$: of(requestCertificates(options)),
          page: {
            ...state$.value.page,
            filter,
          },
        });

    return api.graphApi(query, { options }).pipe(
      map(({ pages, qualityCertificates: pageDocuments }) => {
        const page = pages['qualityCertificate'];
        if (!page)
          return null;

        const brands = pageDocuments.brands;
        if (!brands) {
          page.component = PageComponentNames.NotFound;
          return { page };
        }
        page.docs = pageDocuments.certificates.certificateList;
        page.brands = brands;
        page.component = PageComponentNames.QualityCertificates;
        page.size = size;
        page.filter = filter;
        page.backTo = backTo;
        page.totalCount = pageDocuments.certificates.totalCount;
       
        return { page };
      }),
      initSystemPageContent(),
    );
  };
}

export function createOptions(params, filter, onlyItems) {
  const index = (params && params.index) || 0;
  const page = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  return { ...filter, page };
}

export function normalizeFilter(filter) {
  if (filter && filter.orderDate)
    return filter;
}

function getNumberOfDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}