import { BALANCE_INFO_REQUESTED, balanceInfoReceived } from './actions';
import { mergeMap, pluck, map } from 'rxjs/operators';
import { createBalanceInfoQuery } from './queries';
import { merge } from 'rxjs';
import { ofType } from 'redux-observable';

export default function (action$, _state$, { api }) {

  const balanceInfoRequested$ = action$.pipe(
    ofType(BALANCE_INFO_REQUESTED),
    mergeMap(({ payload: { } }) => {
      return api.graphApi(createBalanceInfoQuery).pipe(
        pluck('balanceInfo'),
        map(records => balanceInfoReceived(records)),
      );
    }),
  );

  return merge(balanceInfoRequested$);
}