import { AbilityTo } from 'behavior/user/constants';

export const enum DocumentType {
  Order = 'Order',
  Quote = 'Quote',
  Invoice = 'Invoice',
  InvoiceOrder = 'InvoiceOrder',
  ReturnOrder = 'ReturnOrder',
  ReturnReceipt = 'ReturnReceipt',
  Shipment = 'Shipment',
  CreditNote = 'CreditNote',
}

export const DocumentAbilitiesMap = {
  [DocumentType.Order]: AbilityTo.ViewOrders,
  [DocumentType.Quote]: AbilityTo.ViewQuotes,
  [DocumentType.Invoice]: AbilityTo.ViewInvoices,
  [DocumentType.InvoiceOrder]: AbilityTo.ViewInvoices,
  [DocumentType.ReturnOrder]: AbilityTo.ViewReturnOrders,
  [DocumentType.ReturnReceipt]: AbilityTo.ViewReturnReceipts,
  [DocumentType.Shipment]: AbilityTo.ViewShipments,
  [DocumentType.CreditNote]: AbilityTo.ViewCreditNotes,
} as const;

export const enum ReorderResult {
  Success = 'SUCCESS',
  BasketNotEmpty = 'BASKET_NOT_EMPTY',
  NoProducts = 'NO_PRODUCTS',
  OrderNotFound = 'ORDER_NOT_FOUND',
}

export const enum EditResult {
  Success = 'SUCCESS',
  BasketNotEmpty = 'BASKET_NOT_EMPTY',
  NoDocument = 'NO_DOCUMENT',
}

export const enum QuoteStatus {
  Expired = 'Expired',
  InReview = 'InReview',
  Orderable = 'Orderable',
  Cancelled = 'Cancelled',
}

export const enum PromoteQuoteStatus {
  Succeed = 'SUCCEED',
  CreditLimitForceOnlinePayment = 'CREDIT_LIMIT_FORCE_ONLINE_PAYMENT',
  CreditLimitWarning = 'CREDIT_LIMIT_WARNING',
  CreditLimitError = 'CREDIT_LIMIT_ERROR',
  OutOfStock = 'OUT_OF_STOCK',
  ContainsNonOrderableProducts = 'CONTAINS_NON_ORDERABLE_PRODUCTS',
  QuoteNotFound = 'QUOTE_NOT_FOUND',
}

export const ChildDocumentTypes = [
  DocumentType.Invoice,
  DocumentType.ReturnReceipt,
  DocumentType.CreditNote,
  DocumentType.Shipment,
] as const;

export const enum OrderTrackingStatusSanaText {
    CREDITO = 'Order_TrackingStatus_CreditCheck',
    PREPARACION = 'Order_TrackingStatus_OrderPreparation',
    FACTURACION = 'Order_TrackingStatus_OrderInvoicing',
    DESPACHO = 'Order_TrackingStatus_OrderReadyDispatch',
    ENTREGADO = 'Order_TrackingStatus_OrderDelivered',
}

export const OrderTrackingStatusCodeCreditCheck = 'CREDITO';
export const OrderTrackingStatusCodeOrderPreparation = 'PREPARACION';
export const OrderTrackingStatusCodeOrderInvoicing = 'FACTURACION';
export const OrderTrackingStatusCodeOrderReadyDispatch = 'DESPACHO';
export const OrderTrackingStatusCodeOrderDelivered = 'ENTREGADO';

export const enum ArrayOrderTrackingStatusCodes {
    CREDITO = 0,
    PREPARACION = 1,
    FACTURACION = 2,
    DESPACHO = 3,
    ENTREGADO = 4,
}