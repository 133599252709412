export const CERTIFICATES_REQUESTED = 'CERTIFICATES_REQUESTED';
export const requestCertificates = (options) => ({
  type: CERTIFICATES_REQUESTED,
  payload: { options },
});

export const CERTIFICATES_RECEIVED = 'CERTIFICATES_RECEIVED';
export const certificatesReceived = (documents, page) => ({
  type: CERTIFICATES_RECEIVED,
  payload: { documents, page },
});