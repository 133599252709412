import { getCustomDateFormatForCulture } from './customDateFormats';

type DateInput = string | number | Date;

function getDate(date: DateInput): Date {
    if (date instanceof Date)
        return date;

    return new Date(date);
}

export function getToLocaleDate(culture: string, timeZone?: 'UTC'): (date: DateInput | null) => string | null {
    const format =
        getCustomDateFormatForCulture(culture) ||
        getCachedFormatFunc(timeZone || '', culture, timeZone ? { timeZone } : undefined);

    return date => date !== null ? format(getDate(date)) : null;
}

export function toLocaleDate(date: DateInput | null, culture: string, timeZone?: 'UTC'): string | null {
    return getToLocaleDate(culture, timeZone)(date);
}

const longDateWithWeekdayOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

const longDateWithoutWeekdayOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
};

export function toLongLocaleDate(date: DateInput | null, culture: string, includeWeekday = true) {
    if (date === null)
        return null;

    const options = includeWeekday ? longDateWithWeekdayOptions : longDateWithoutWeekdayOptions;
    const format = getCachedFormatFunc('long' + includeWeekday, culture, options);
    return format(getDate(date));
}

export function toLocalMonth(date: DateInput | null, culture: string) {
    if (date === null)
        return null;

    const format = getCachedFormatFunc('month', culture, { month: 'long' });
    return format(getDate(date));
}

export function toLocalMonthAndYear(date: DateInput | null, culture: string) {
    if (date === null)
        return null;

    const format = getCachedFormatFunc('monthAndYear', culture, { month: 'long', year: 'numeric' });
    return format(getDate(date));
}

export function toLocalYear(date: DateInput | null, culture: string) {
    if (date === null)
        return null;

    const format = getCachedFormatFunc('year', culture, { year: 'numeric' });
    return format(getDate(date));
}

export function toLocalWeekday(date: DateInput | null, culture: string, type: 'short' | 'long') {
    if (date === null)
        return null;

    const format = getCachedFormatFunc('weekday' + type, culture, { weekday: type });
    return format(getDate(date));
}

export function toLocaleTime(dateString: string, culture: string, hideSeconds = false): string {
    const format = getCachedFormatFunc('time' + hideSeconds, culture, {
        hour: 'numeric',
        minute: 'numeric',
        second: hideSeconds ? undefined : 'numeric',
    });

    return format(getDate(dateString));
}

type Formatter = {
    culture: string;
    format: (date?: Date | number) => string;
};

const getCachedFormatFunc = function () {
    const caches = new Map<string, Formatter>();

    return (key: string, culture: string, options?: Intl.DateTimeFormatOptions) => {
        culture = culture || 'en-US';
        let formatter = caches.get(key);

        if (!formatter || formatter.culture !== culture) {
            const dateFormat = getDateTimeFormatForCulture(culture, options);

            formatter = {
                culture,
                format: dateFormat.format,
            };

            caches.set(key, formatter);
        }

        return formatter.format;
    };

    function getDateTimeFormatForCulture(culture: string, options?: Intl.DateTimeFormatOptions) {
        const dateFormat = new Intl.DateTimeFormat(culture, options);

        const numberingSystem = dateFormat.resolvedOptions().numberingSystem;
        if (!numberingSystem || numberingSystem === 'latn')
            return dateFormat;

        culture += '-u-nu-latn';
        // Force gregorian calendar for arabic languages.
        if (culture.startsWith('ar'))
            culture += '-ca-gregory';

        return new Intl.DateTimeFormat(culture, options);
    }
}();
