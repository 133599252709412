import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestCertificate } from './actions';
import { PageComponentNames } from '../componentNames';

export const size = 10;

export default function (query) {
  
    return function ({ params }, state$, { api }) {
      const id = params.id;


      return api.graphApi(query, { id }).pipe(
        map(({ pages, qualityCertificates: pageDocuments }) => {

          const page = pages['qualityCertificateDetails'];
          if (!page)
            return null;

          if (!pageDocuments.documentsCertificate || !pageDocuments.documentsCertificate.qualityCertificateId || !pageDocuments.documentsCertificate.documentsCertificateCollection)
            return null;

          const currentPageState = state$.value.page;

          for (const doc of currentPageState.docs) {
            if (doc.id === pageDocuments.documentsCertificate.qualityCertificateId)
              page.doc = doc;
          }

          if (!page.doc)
            return null;

          page.docs = pageDocuments.documentsCertificate.documentsCertificateCollection;
          page.component = PageComponentNames.QualityCertificate;
          page.size = size;

          return { page };
        }),
        initSystemPageContent(),
      );
  };
}