import type { Document } from './types';
import { QuoteStatus, DocumentType } from './constants';

export const isDocumentCancelled = (document: Document) =>
  document.status === QuoteStatus.Cancelled || document.status === 'CANCELLED';

export const isDocumentPromotableQuote = (document: Document, documentType: keyof typeof DocumentType) =>
  documentType === DocumentType.Quote && document.status === QuoteStatus.Orderable;

const fieldPerDocumentType = {
  [DocumentType.Order]: 'orders',
  [DocumentType.Quote]: 'quotes',
  [DocumentType.Invoice]: 'invoices',
  [DocumentType.ReturnOrder]: 'returnOrders',
  [DocumentType.CreditNote]: 'creditNotes',
  [DocumentType.ReturnReceipt]: 'returnReceipts',
  [DocumentType.Shipment]: 'shipments',
} as const;

export function getDocumentField(documentType: keyof typeof fieldPerDocumentType) {
  return fieldPerDocumentType[documentType];
}

export function getIsPrintModeParam(documentType: keyof typeof fieldPerDocumentType) {
  if(documentType == DocumentType.Invoice)
    return ',isPrintMode:$isPrintMode'

  return '';
}

export function getIsPrintModeParamDefinition(documentType: keyof typeof fieldPerDocumentType) {
  if(documentType == DocumentType.Invoice)
    return ',$isPrintMode:Boolean'

  return '';
}

export function getIsPrintModeParamValue(documentType: keyof typeof fieldPerDocumentType) {
  if(documentType == DocumentType.Invoice)
    return 'originalInvoice'

  return '';
}

const documentDetailsFieldMap = {
  [DocumentType.Order]: 'orderDetails',
  [DocumentType.Quote]: 'quoteDetails',
  [DocumentType.Invoice]: 'invoiceDetails',
  [DocumentType.ReturnOrder]: 'returnOrderDetails',
  [DocumentType.CreditNote]: 'creditNoteDetails',
  [DocumentType.ReturnReceipt]: 'returnReceiptDetails',
  [DocumentType.Shipment]: 'shipmentDetails',
} as const;

export function getDocumentDetailsField(documentType: keyof typeof documentDetailsFieldMap) {
  return documentDetailsFieldMap[documentType];
}
