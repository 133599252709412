import { CERTIFICATES_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [CERTIFICATES_RECEIVED]: onDocumentsReceived,
});

function onDocumentsReceived(state, action) {
  const {
    documents,
    page,
  } = action.payload;
  if (!state.docs || page === 0)
    return {
      ...state,
      docs: documents.certificateList,
      totalCount: documents.totalCount,
    };

  const { certificateList, totalCount } = documents;
  return {
    ...state,
    docs: state.docs.concat(certificateList),
    totalCount:totalCount,
  };
}