import { BALANCE_INFO_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [BALANCE_INFO_RECEIVED]: balanceInfoReceived,
});

const initialState = {
  balanceInfo: "",
};
function balanceInfoReceived(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BALANCE_INFO_RECEIVED:
      return {
        ...state,
        balanceInfo: {
          balance: payload.data.balance
        },
      };
    default:
      return state;
  }
}